* {
  box-sizing: border-box;
}

body {
  color: #383f4d;
  line-height: 1.5;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    sans-serif;
}

/* 
=================
Table
=====================
*/

.table_container {
  max-width: 750px;
  /* max-height: 500px; */
  margin: 0 auto;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  /* Initial setting - let the table take as much width as it needs */
  width: auto;
  min-width: 100%;
  /* Ensures table is at least as wide as the container */
  /* More styling for your table (border, size, etc.) can go here */
}

@media (min-width: 100%) {
  table {
    justify-content: flex-start;
    /* Aligns table to the left when it is wider than the container */
  }
}

caption {
  font-style: italic;
  font-size: 90%;
  margin-bottom: 10px;
}

.table th {
  background: #fff;
  padding: 8px 10px;
  border-bottom: 1px solid #1a1a1a;
  font-weight: bold;
  text-align: left;
}

.table th.up {
  background-image: url("./images/up_arrow.png");
}

.table th.down {
  background-image: url("./images/down_arrow.png");
}

.table th.default {
  background-image: url("./images/default.png");
}

th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.table td {
  border-top: 1px solid #ddd;
  padding: 8px 10px;
}

.table tbody tr:first-child td {
  border-top: none;
}

.table tbody tr:nth-child(n) td {
  background: #eff0f0;
}

.table tbody tr:nth-child(2n) td {
  background: #fff;
}

.bibtex {
  text-align: left;
}