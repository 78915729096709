.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.publication-links {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}


table {
  width: 100%;
  min-width: 300px;
  /* Ensure minimum width even if screen is too small */
  border-collapse: separate;
}

table,
th,
td {
  border: 1px solid #ccc;
}

table {
  border-left: none;
}

th,
td {
  padding: 8px;
}

.table-container th {
  text-align: center;
  vertical-align: middle;
}

.model-col {
  width: 200px;
  text-align: left;
}

.globalAverage-col {
  width: 115px;
}

.category-checkboxes {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
}

.category-checkboxes label {
  margin-right: 10px;
  font-size: 16px;
  color: #007bff;
  cursor: pointer;
}


.table-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* Align items at the top */
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  /* Take the full width up to 80% of the parent container */
  margin: 10px auto;
  /* Center the table-container horizontally */
  padding: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Optional: adds shadow for better visibility */
}

.search-bar {
  width: 100%;
  margin-bottom: 0.5rem;
}

.filter-bar {
  width: 100%;
  margin-bottom: 1rem;
}

.search-bar > input {
  width: 100%;
  padding: .5rem;
}

.scrollable-table {
  margin: 0 auto;
  /* Center the scrollable-table horizontally */
  overflow-y: auto;
  overflow-x: auto;
  max-height: 800px;
  width: 100%;
}

.scrollable-table thead tr {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.4), inset 0 1px 0 #ccc;
}


.category-group {
  margin-right: 10px;
  margin-left: 10px;
  width: 250px;
  display: flex;
  justify-content: center;
  /* Center content within each category-group */
  flex-direction: column;
  /* Ensure the labels stack vertically */
}

.category-checkboxes label {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 16px;
  color: #007bff;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* Align checkbox and text vertically */
}

.category-checkboxes input[type="checkbox"] {
  margin-right: 8px;
  /* Add space between checkbox and text */
}

.App-header {
  font-size: calc(10px + 2vmin);
}

@media (max-width: 600px) {
  .App-header {
    font-size: calc(8px + 2vmin);
  }

  .table-container {
    max-width: 100%;
    margin: 10px;
  }

  .category-checkboxes {
    flex-direction: column;
    align-items: center;
  }

  .category-group {
    width: 100%;
    margin: 5px 0;
  }

  .table {
    min-width: 0;
  }

  th,
  td {
    padding: 6px;
  }
}

/* Style adjustments for medium screens */
@media (min-width: 601px) and (max-width: 1024px) {
  .App-header {
    font-size: calc(9px + 2vmin);
  }

  .table-container {
    max-width: 90%;
  }
}

@media (max-width: 400px) {
  .App .title.is-1 {
    font-size: 2.75rem !important;
  }
  .App .is-size-5 {
    font-size: 1rem !important;
  }
}


.category-group {
  margin: 5px 0;
}

.month-text {
  font-weight: bold;
  color: #1d7b13; /* Change this to your preferred color */
  font-size: 1.2em; /* Adjust size as needed */
}

.other-controls {
  margin-bottom: 1rem;
}

.clear-filters-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-left: 1rem;
}

.section {
  padding: 1rem;
}

.hero-body {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

td:first-child, th:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 1;
  box-shadow: inset -1px 0 0 #ccc, inset 1px 0 0 #ccc;
}

thead th:first-child {
  z-index: 3;
  box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.1), 0 2px 5px -2px rgba(0, 0, 0, 0.1), inset -1px 0 0 #ccc, inset 1px 0 0 #ccc;
}

.o1-row * {
  background-color: lightcoral !important;
}

.o1-row.not-coding {
  display: none;
}

.liveswebench-callout {
  background-color: rgb(128, 201, 240);
  padding: 1rem;
  border-radius: 10px;
  font-size: 1.25rem;
  color: black;
  word-wrap: break-word;
  display: inline-block;
  text-decoration: none;
  width: fit-content;
}

.liveswebench-callout span {
  display: block;
}

.liveswebench-callout:hover {
  background-color: rgb(122, 220, 233);
}