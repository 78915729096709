.Blog {
  text-align: center;
}

.Blog-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Blog-logo {
    animation: Blog-logo-spin infinite 20s linear;
  }
}

.Blog-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Blog-link {
  color: #61dafb;
}

@keyframes Blog-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

media (max-width: 600px) {
  .App-header {
    font-size: calc(8px + 2vmin);
  }

  .table-container {
    max-width: 100%;
    margin: 10px;
  }

  .category-checkboxes {
    flex-direction: column;
    align-items: center;
  }

  .category-group {
    width: 100%;
    margin: 5px 0;
  }

  .table {
    min-width: 0;
  }

  th,
  td {
    padding: 6px;
  }
}

/* Style adjustments for medium screens */
@media (min-width: 601px) and (max-width: 1024px) {
  .Blog-header {
    font-size: calc(9px + 2vmin);
  }

  .table-container {
    max-width: 90%;
  }
}

@media (max-width: 400px) {
  .Blog .title.is-1 {
    font-size: 2.75rem !important;
  }

  .Blog .is-size-5 {
    font-size: 1rem !important;
  }
}